export const environment = {
  production: true,
  env_name: 'stage',
  APP_VERSION: require('../../package.json').version + '-stage',
  API_BASE_URL: 'https://jameel-stg-api.azurewebsites.net',
  BLOB_BASE_URL: 'https://jameelstagesge.blob.core.windows.net',
  i18nPrefix: '',
  ZENDESK_URL: 'https://jameelksa.zendesk.com',
  ZENDESK_URL_RIDER: 'https://jameelksa.zendesk.com/hc/ar',
  GOOGLE_KEY: 'AIzaSyACbprfEH4kB1OrYZLUVckgLBfpI-x8iNI',
  MAPBOX_KEY:'pk.eyJ1Ijoic3dpZnQ5MiIsImEiOiJjbHB4eHl5aHMwdHNqMm1wZm82eXJpbGZzIn0.NVEee7BW9qLQBZ7dmB6q5A',
};
